<template>
  <div class="create-content-main">
    <div
      class="create-slag-form news-create"
      style="box-shadow: 0 2px 10px rgb(0 0 0 / 20%)"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="isEdit" class="mr-0 col-6 font-title cpx-4 cpy-2">編集</div>
        <div v-else class="mr-0 col-6 font-title cpx-4 cpy-2">ニュース作成</div>
        <!-- son -->
        <div class="cpx-4 col-6 text-right">
          <b-button
            v-b-modal.modal-preview-content
            class="btn-other btn-preview-fixed mr-0"
            style="height: 42px"
            variant="primary"
          >
            プレビュー
          </b-button>
        </div>
      </div>
      <form
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >ニュースタイトル<span class="required">(必須)</span></label
          >
          <div class="cp-4">
            <input
              class="form-input col-sm-12 m-0"
              v-bind:class="{ 'error-input': infoNew.errorTitleRequired }"
              type="text"
              v-model="infoNew.title"
            />
            <div class="message-error" v-if="infoNew.errorTitleRequired">
              {{ feedback.REQUIRED }}
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >ニュース本文<span class="required">(必須)</span></label
          >
          <div class="cp-4">
            <vue-editor
              useCustomImageHandler
              v-model="infoNew.content"
              @image-added="handleImageAdded"
              @image-removed="handleImageRemove"
              class="w-100 h-100"
              style="background-color: white; color: black"
              v-bind:class="{ 'error-input': infoNew.errorContentRequired }"
            ></vue-editor>
            <div class="message-error" v-if="infoNew.errorContentRequired">
              {{ feedback.REQUIRED }}
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >カテゴリ<span class="required">(必須)</span></label
          >
          <div class="cp-4">
            <div class="d-flex">
              <b-form-select
                v-model="infoNew.category"
                :options="optionCategory"
                class="mr-3"
                v-bind:class="{ 'error-input': infoNew.errorCategoryRequired }"
              ></b-form-select>
              <b-button
                variant="success"
                style="border-radius: 50% !important"
                v-b-modal.modal-add-category
                ><font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'plus']"
              /></b-button>
            </div>
            <div class="message-error" v-if="infoNew.errorCategoryRequired">
              {{ feedback.REQUIRED }}
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >投稿日時<span class="required">(必須)</span></label
          >
          <div class="cp-4">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="infoNew.postTime"
                :config="optionsDatePostTime"
                autocomplete="off"
                style="margin-bottom: 1rem"
                placeholder="日付を選択"
                class="m-0"
                v-bind:class="{ 'error-input': infoNew.errorPostTimeRequired }"
              ></date-picker>
              <div class="message-error" v-if="infoNew.errorPostTimeRequired">
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >リダイレクトURL</label
          >
          <div class="cp-4">
            <input
              class="form-input col-sm-12 m-0"
              v-bind:class="{
                'error-input': infoNew.errorUrlRedirectInvalid,
              }"
              type="text"
              v-model="infoNew.urlRedirect"
            />
            <div class="message-error" v-if="infoNew.errorUrlRedirectInvalid">
              {{ feedback.INVALID }}
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >表示設定</label
          >
          <div class="cp-4">
            <div class="col-sm-10 pl-0">
              <label
                class="custom-ListLabel"
                v-bind:class="{
                  'btn-share-freesize-active': index === infoNew.typeDisplay,
                }"
                v-on:click="clickTypeDisplay(index, $event)"
                v-for="(item, index) in listLabelDisplay"
                :key="index"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
        <div v-if="infoNew.typeDisplay === 1" class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >基本設定(どのスラグを持つユーザーのみ表示させるか?を設定)</label
          >
          <div class="cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.slagIdVisible1"
                tag-placeholder="購入付与スラグ追加"
                placeholder="購入付与スラグ追加"
                label="slag_name"
                track-by="slag_name"
                class="m-0"
                :options="infoNew.optionSlagVisible"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              ></multiselect>
            </div>
          </div>
        </div>
        <div v-if="infoNew.typeDisplay === 1" class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
          >
          <div class="cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.slagIdVisible2"
                tag-placeholder="購入付与スラグ追加"
                placeholder="購入付与スラグ追加"
                label="slag_name"
                track-by="slag_name"
                :options="infoNew.optionSlagVisible"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              ></multiselect>
            </div>
          </div>
        </div>
        <div v-if="infoNew.typeDisplay === 1" class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >and2(基本設定 + and1+2の3点のスラグを持つユーザーだけに表示)</label
          >
          <div class="cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.slagIdVisible3"
                tag-placeholder="購入付与スラグ追加"
                placeholder="購入付与スラグ追加"
                label="slag_name"
                track-by="slag_name"
                :options="infoNew.optionSlagVisible"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              ></multiselect>
            </div>
          </div>
        </div>
        <div v-if="infoNew.typeDisplay === 2" class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >非表示(以下のスラグを持つユーザーは非表示)</label
          >
          <div class="cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.slagIdHidden"
                tag-placeholder="購入付与スラグ追加"
                placeholder="購入付与スラグ追加"
                label="slag_name"
                track-by="slag_name"
                :options="infoNew.optionSlagHidden"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >オプション：ホバーウィンドウ・カウントダウン設定</label
          >
          <div class="cp-4">
            <div class="col-sm-10 pl-0">
              <label
                class="custom-ListLabel"
                v-bind:class="{
                  'btn-share-freesize-active': index === infoNew.typeCountDown,
                }"
                v-on:click="clickTypeCountDown(index, $event)"
                v-for="(item, index) in listLabelCountDown"
                :key="index"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="infoNew.typeCountDown === 0 || infoNew.typeCountDown === 1"
            class="m-0 row-input"
          >
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >カウントダウン 名前 (例終了まであと)<span class="required"
                >(必須)</span
              ></label
            >
            <div class="cp-4">
              <input
                class="form-input col-sm-12 m-0"
                v-bind:class="{
                  'error-input': infoNew.errorNameCountdownRequired,
                }"
                type="text"
                v-model="infoNew.nameCountdown"
              />
              <div
                class="message-error"
                v-if="infoNew.errorNameCountdownRequired"
              >
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
          <div v-if="infoNew.typeCountDown === 0" class="m-0 row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >カウントダウン 日にち<span class="required">(必須)</span></label
            >
            <div class="cp-4">
              <div class="fd-admin-listproperty-card-filter filter-date">
                <date-picker
                  name="date"
                  v-model="infoNew.dateCountdown"
                  :config="optionsDateCountdownTime"
                  autocomplete="off"
                  style="margin-bottom: 1rem"
                  placeholder="日付を選択"
                  class="m-0"
                  v-bind:class="{
                    'error-input': infoNew.errorDateCountdownRequired,
                  }"
                ></date-picker>
                <div
                  class="message-error"
                  v-if="infoNew.errorDateCountdownRequired"
                >
                  {{ feedback.REQUIRED }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="infoNew.typeCountDown === 1" class="m-0 row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >サイトアクセス後カウントダウン時間(例:1日＝24時間30分＝0.5時間)小数点第1位まで<span
                class="required"
                >(必須)</span
              ></label
            >
            <div class="cp-4">
              <input
                class="form-input col-sm-12 m-0"
                v-bind:class="{
                  'error-input': infoNew.errorTimeReadCountdownRequired,
                }"
                type="number"
                v-model.number="infoNew.timeReadCountdown"
                onkeypress="return event.charCode === 44 || event.charCode === 46 || (event.charCode >= 48 && event.charCode <= 57)"
              />
              <div
                class="message-error"
                v-if="infoNew.errorTimeReadCountdownRequired"
              >
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
          <div v-if="infoNew.typeCountDown !== 3" class="m-0 row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >カウントダウン終了後にジャンプするURL (http://〜)<span
                class="required"
                >(必須)</span
              ></label
            >
            <div class="cp-4">
              <input
                class="form-input col-sm-12 m-0"
                v-bind:class="{
                  'error-input':
                    infoNew.errorUrlCountdownRequired ||
                    infoNew.errorUrlCountdownInvalid,
                }"
                type="text"
                v-model="infoNew.urlCountdown"
              />
              <div
                class="message-error"
                v-if="infoNew.errorUrlCountdownRequired"
              >
                {{ feedback.REQUIRED }}
              </div>
              <div
                class="message-error"
                v-if="infoNew.errorUrlCountdownInvalid"
              >
                {{ feedback.INVALID }}
              </div>
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >オプション：コンテンツ付与</label
          >
          <div class="cp-4">
            <div
              class="custom-select-2 w-100 custome-multi-slag position-relative"
            >
              <multiselect
                v-model="infoNew.contentId"
                tag-placeholder="コンテンツを選択してください"
                placeholder="コンテンツを選択してください"
                label="text"
                track-by="value"
                :options="optionContent"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
                :loading="loadingContent"
                class="m-0"
                v-bind:class="{ 'error-input': infoNew.errorContentIdRequired }"
              ></multiselect>
              <div class="message-error" v-if="infoNew.errorContentIdRequired">
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold pl-0"
            style="font-weight: 700 !important"
            >オプション：ポイント付与</label
          >
          <div class="col-sm-12 m-0">
            <input
              class="form-input col-sm-2"
              type="text"
              v-model="infoNew.point"
            />
            <span class="ml-2" style="align-self: center">ポイント</span>
          </div>
          <div class="col-sm-12">
            <span class="mr-2" style="align-self: center">付与回数：</span>
            <input
              class="form-input col-sm-2"
              type="text"
              v-model="infoNew.timeGrantPoint"
            />
            <span class="ml-2" style="align-self: center">回</span>
          </div>
        </div> -->
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >オプション：スラグ付与</label
          >
          <div class="cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.slagId"
                tag-placeholder="スラグを選択してください"
                placeholder="スラグを選択してください"
                label="slag_name"
                track-by="slag_name"
                :options="optionSlag"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
                class="m-0"
                v-bind:class="{ 'error-input': infoNew.errorSlagRequired }"
              ></multiselect>
              <div class="message-error" v-if="infoNew.errorSlagRequired">
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
        </div>
        <div class="m-0 row-input">
          <label
            class="col-sm-12 font-weight-bold cp-label label-news"
            style="font-weight: 700 !important"
            >公開ステータス</label
          >
          <div class="cp-4">
            <div class="w-100">
              <b-form-select
                v-model="infoNew.isPublic"
                :options="optionStatus"
                class="col-sm-2 mr-3 m-0"
              ></b-form-select>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 pb-4">
        <b-button
          v-if="isEdit"
          v-b-modal.delete-modal-new
          variant="danger"
          class="mr-4"
        >
          ページを削除
        </b-button>
        <button
          v-on:click.prevent="saveNew()"
          :disabled="loadingSave"
          class="mr-4 btn-save"
        >
          <b-spinner v-if="loadingSave" small></b-spinner>
          編集内容を反映
        </button>
        <b-button variant="secondary" class="mr-4" v-on:click="returnListNew()"
          >戻る</b-button
        >
      </div>
    </div>
    <!-- Modal Add Category -->
    <b-modal
      id="modal-add-category"
      size="lg"
      title="カテゴリを作成"
      hide-footer
      @show="resetModalCategory"
      @hidden="resetModalCategory"
    >
      <CRow>
        <CCol sm="12">
          <div class="d-flex flex-column">
            <CInput
              label="種別名"
              v-model="category.name"
              :isValid="category.errorNameRequired"
              :invalidFeedback="category.messageNameRequired"
            />
          </div>
        </CCol>
        <CCol sm="12">
          <div class="d-flex justify-content-center">
            <button
              v-on:click.prevent="createCategory()"
              variant="success"
              class="mr-4 btn-save"
              :disabled="loadingCategory"
            >
              作成
              <b-spinner v-if="loadingCategory" small></b-spinner>
            </button>
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-add-category')"
              class="mx-2"
              >閉じる</b-button
            >
          </div>
        </CCol>
      </CRow>
    </b-modal>
    <!-- Modal Add Category -->
    <!-- Modal preview content -->
    <b-modal
      id="modal-preview-content"
      size="xl"
      :title="infoNew.title"
      hide-footer
      scrollable
    >
      <CRow>
        <CCol sm="12">
          <div class="d-flex flex-column" v-html="infoNew.content"></div>
        </CCol>
        <CCol sm="12">
          <div class="d-flex justify-content-center">
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-preview-content')"
              class="mx-2"
              >閉じる</b-button
            >
          </div>
        </CCol>
      </CRow>
    </b-modal>
    <!-- Modal preview content -->
    <!-- Modal delete new -->
    <b-modal id="delete-modal-new" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          v-on:click.prevent="deleteNew()"
          :disabled="loadingDelete"
          ><b-spinner v-if="loadingDelete" small></b-spinner>削除</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="$bvModal.hide('delete-modal-new')"
        >
          キャンセル
        </b-button>
      </div>
    </b-modal>
    <!-- Modal delete new -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { FeedBack } from "@/utils/feedback.js";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants.js";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "CreateCategory",
  components: {
    VueEditor,
  },
  data() {
    return {
      feedback: FeedBack,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      infoNew: {
        title: "",
        errorTitleRequired: false,
        content: "",
        errorContentRequired: false,
        category: "",
        errorCategoryRequired: false,
        postTime: "",
        errorPostTimeRequired: false,
        typeDisplay: 0,
        typeCountDown: 0,
        contentId: [],
        errorContentIdRequired: false,
        point: 0,
        timeGrantPoint: 0,
        slagId: [],
        errorSlagRequired: false,
        isPublic: 0,
        userDisplay: [],
        errorUserDisplayRequired: false,
        userHidden: [],
        errorUserHiddenRequired: false,
        nameCountdown: "",
        errorNameCountdownRequired: false,
        dateCountdown: "",
        errorDateCountdownRequired: false,
        urlCountdown: "",
        errorUrlCountdownRequired: false,
        errorUrlCountdownInvalid: false,
        timeReadCountdown: null,
        errorTimeReadCountdownRequired: false,
        slagIdVisible1: [],
        slagIdVisible2: [],
        slagIdVisible3: [],
        slagIdHidden: [],
        optionSlagVisible: [],
        optionSlagHidden: [],
        urlRedirect: "",
        errorUrlRedirectInvalid: false,
      },
      // listCheckSlag: [],
      category: {
        name: "",
        errorNameRequired: null,
        messageNameRequired: "",
      },
      optionCategory: [],
      isInputCategory: false,
      optionsDatePostTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      optionsDateCountdownTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      listLabelDisplay: [
        { value: 0, text: "全員に表示" },
        { value: 1, text: "一部に表示" },
        { value: 2, text: "一部に非表示" },
      ],
      listLabelCountDown: [
        { value: 0, text: "日時指定方式" },
        { value: 1, text: "アクセス後カウント方式" },
        { value: 2, text: "ワンタイムオファー" },
        { value: 3, text: "カウントダウンOFF" },
      ],
      optionContent: [],
      optionSlag: [],
      optionStatus: [
        { value: 0, text: "非公開" },
        { value: 1, text: "公開中" },
      ],
      optionUser: [],
      loadingDelete: false,
      loadingSave: false,
      loadingCategory: false,
      loadingContent: false,
      page: 2,
      isEdit: false,
    };
  },
  created() {
    const { params } = this.$route;
    const { id } = params;
    const request = {
      id: id,
      shop_id: this.shop_id,
      screen: "admin",
    };
    if (id) {
      this.isEdit = true;
      this.getNewByIdAdmin(request);
    }
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getALLlistSlag(request);
    this.getListCategoryNew(request);
    this.getListContent(requestContent);
    this.getListUser(requestContent);
  },
  computed: {
    ...mapGetters([
      "listSlag",
      "success",
      "message",
      "error",
      "listCategoryNew",
      "listContent",
      "detailNew",
      "listUser",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.loadingDelete = false;
        this.loadingSave = false;
        this.loadingCategory = false;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        if (this.$route.params.shopId) {
          this.$router.push({
            name: "list new admin",
            params: { shopId: this.shop_id },
          });
        } else {
          this.$router.push({ name: "list new admin domain" });
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.loadingDelete = false;
        this.loadingSave = false;
        this.loadingCategory = false;
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    listUser() {
      this.optionUser = this.listUser.data.map((item) => ({
        value: item.id,
        text: item.email,
      }));
    },
    detailNew() {
      this.infoNew.title = this.detailNew.news_title;
      this.infoNew.content = this.detailNew.news_content;
      this.infoNew.category = this.detailNew.category_news_id;
      this.infoNew.postTime = this.detailNew.post_date;
      this.infoNew.typeDisplay = this.detailNew.display_type;
      this.infoNew.typeCountDown = this.detailNew.windows_hover_type;
      this.infoNew.point = this.detailNew.point;
      this.infoNew.timeGrantPoint = this.detailNew.number_point;
      this.infoNew.isPublic = this.detailNew.is_public;
      this.infoNew.contentId = this.detailNew.contents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      this.infoNew.slagId = this.detailNew.slags.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
      this.infoNew.nameCountdown = this.detailNew.countdown_name;
      this.infoNew.dateCountdown = this.detailNew.countdown_date;
      this.infoNew.timeReadCountdown = this.detailNew.countdown_time_read;
      this.infoNew.urlCountdown = this.detailNew.countdown_url;
      this.infoNew.urlRedirect = this.detailNew.url_redirect;
      this.addSlag();
    },
    listSlag() {
      this.optionSlag = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
      this.infoNew.optionSlagVisible = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
      this.infoNew.optionSlagHidden = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
      this.addSlag();
    },
    listCategoryNew() {
      this.optionCategory = this.listCategoryNew.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      this.optionCategory.unshift({
        value: "",
        text: "カテゴリを選んでください",
      });
    },
    listContent() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollContent);
      let listContent = [];
      listContent = this.listContent.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContent.forEach((element) => {
        this.optionContent.push(element);
      });
      this.loadingContent = false;
    },
    "infoNew.title"() {
      if (this.infoNew.title) {
        this.infoNew.errorTitleRequired = false;
      }
    },
    "infoNew.content"() {
      if (this.infoNew.content) {
        this.infoNew.errorContentRequired = false;
      }
    },
    "infoNew.category"() {
      if (this.infoNew.category) {
        this.infoNew.errorCategoryRequired = false;
      }
    },
    "infoNew.postTime"() {
      if (this.infoNew.postTime) {
        this.infoNew.errorPostTimeRequired = false;
      }
    },
    "infoNew.contentId"() {
      if (this.infoNew.contentId.length !== 0) {
        this.infoNew.errorContentIdRequired = false;
      }
    },
    "infoNew.slagId"() {
      if (this.infoNew.slagId.length !== 0) {
        this.infoNew.errorSlagRequired = false;
      }
    },
    "category.name"() {
      if (this.category.name) {
        this.category.errorNameRequired = null;
        this.category.messageNameRequired = "";
      }
    },
    "infoNew.userDisplay"() {
      this.infoNew.errorUserDisplayRequired = false;
    },
    "infoNew.userHidden"() {
      this.infoNew.errorUserHiddenRequired = false;
    },
    "infoNew.nameCountdown"() {
      this.infoNew.errorNameCountdownRequired = false;
    },
    "infoNew.dateCountdown"() {
      this.infoNew.errorDateCountdownRequired = false;
    },
    "infoNew.timeReadCountdown"() {
      this.infoNew.errorTimeReadCountdownRequired = false;
    },
    "infoNew.urlCountdown"() {
      this.infoNew.errorUrlCountdownRequired = false;
      this.infoNew.errorUrlCountdownInvalid = false;
    },
    "infoNew.urlRedirect"() {
      this.infoNew.errorUrlRedirectInvalid = false;
    },
  },
  methods: {
    ...mapActions({ createNew: "createNew" }),
    ...mapActions({ updateNew: "updateNew" }),
    ...mapActions({ getNewByIdAdmin: "getNewByIdAdmin" }),
    ...mapActions({ getListContent: "getListContent" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListCategoryNew: "getListCategoryNew" }),
    ...mapActions({ getListUser: "getListUser" }),
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContent(requestContent);
      }
    },
    createCategory() {
      this.loadingCategory = true;
      if (this.category.name.trim() === "") {
        this.category.errorNameRequired = false;
        this.category.messageNameRequired = FeedBack.REQUIRED;
        this.loadingCategory = false;
      } else {
        const request = {
          title: this.category.name,
          shop_id: this.shop_id,
        };
        Api.adminRequestServer
          .post(`/${Urls.ADMIN}/${Urls.CATEGORY_NEW}/${Urls.CREATE}`, request)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$toasted.success(data.message);
              this.loadingCategory = false;
              const request = {
                shop_id: this.shop_id,
                screen: "admin",
              };
              this.getListCategoryNew(request);
              this.$bvModal.hide("modal-add-category");
            } else {
              this.loadingCategory = false;

              this.$toasted.error(this.data.message);
            }
          })
          .catch((error) => {
            this.loadingCategory = false;

            this.$toasted.error(error.message);
          });
      }
    },
    clickTypeCountDown(index) {
      if (this.infoNew.typeCountDown !== index) {
        this.infoNew.typeCountDown = index;
      }
    },
    clickTypeDisplay(index) {
      if (this.infoNew.typeDisplay !== index) {
        this.infoNew.typeDisplay = index;
      }
    },
    resetModalCategory() {
      this.category_name = "";
      this.category_code = "";
    },
    deleteNew() {
      this.loadingDelete = true;
      const { id } = this.$route.params;
      Api.adminRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.NEWS}/${id}?shop_id=${this.shop_id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.loadingDelete = false;
            this.$bvModal.hide("delete-modal-new");

            this.$toasted.success(data.message);
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "list new admin",
                params: { shopId: this.shop_id },
              });
            } else {
              this.$router.push({ name: "list new admin domain" });
            }
          } else {
            this.loadingDelete = false;

            this.$toasted.error(this.data.message);
          }
        })
        .catch((error) => {
          this.loadingDelete = false;

          this.$toasted.error(error.message);
        });
    },
    returnListNew() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "list new admin",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({ name: "list new admin domain" });
      }
    },
    saveNew() {
      this.loadingSave = true;
      let error = false;
      const {
        title,
        content,
        category,
        postTime,
        typeCountDown,
        nameCountdown,
        dateCountdown,
        timeReadCountdown,
        urlCountdown,
        slagIdVisible1,
        slagIdVisible2,
        slagIdVisible3,
        slagIdHidden,
        urlRedirect,
      } = this.infoNew;
      let listIdSlagVisible1 = slagIdVisible1.map(function (item) {
        return item.slag_id;
      });
      let listIdSlagVisible2 = slagIdVisible2.map(function (item) {
        return item.slag_id;
      });
      let listIdSlagVisible3 = slagIdVisible3.map(function (item) {
        return item.slag_id;
      });
      let listIdSlagHidden = slagIdHidden.map(function (item) {
        return item.slag_id;
      });
      if (title.trim() === "") {
        this.infoNew.errorTitleRequired = true;
        error = true;
      }
      if (content === "") {
        this.infoNew.errorContentRequired = true;
        error = true;
      }
      if (category === "") {
        this.infoNew.errorCategoryRequired = true;
        error = true;
      }
      if (!postTime) {
        this.infoNew.errorPostTimeRequired = true;
        error = true;
      }
      // if (this.infoNew.contentId.length === 0) {
      //   this.infoNew.errorContentIdRequired = true;
      //   error = true;
      // }
      // if (this.infoNew.slagId.length === 0) {
      //   this.infoNew.errorSlagRequired = true;
      //   error = true;
      // }
      if ((typeCountDown === 0 || typeCountDown === 1) && !nameCountdown) {
        this.infoNew.errorNameCountdownRequired = true;
        error = true;
      }
      if (typeCountDown === 0 && !dateCountdown) {
        this.infoNew.errorDateCountdownRequired = true;
        error = true;
      }
      if (typeCountDown === 1 && !timeReadCountdown) {
        this.infoNew.errorTimeReadCountdownRequired = true;
        error = true;
      }
      if (typeCountDown != 3 && !urlCountdown) {
        this.infoNew.errorUrlCountdownRequired = true;
        error = true;
      }
      if (
        typeCountDown != 3 &&
        urlCountdown &&
        !urlCountdown.match(Constants.REGEX_URL)
      ) {
        this.infoNew.errorUrlCountdownInvalid = true;
        error = true;
      }
      if (urlRedirect && !urlRedirect.match(Constants.REGEX_URL)) {
        this.infoNew.errorUrlRedirectInvalid = true;
        error = true;
      }
      if (error) {
        this.loadingSave = false;

        this.$toasted.error("必須項目のご入力をお願いします。");
      } else {
        let listContentId = [];
        let listSlagId = [];
        this.infoNew.contentId.forEach((element) => {
          listContentId.push(element.value);
        });
        this.infoNew.slagId.forEach((element) => {
          listSlagId.push(element.slag_id);
        });
        const request = {
          id: this.$route.params.id ? this.$route.params.id : "",
          shop_id: this.shop_id,
          news_title: this.infoNew.title,
          news_content: this.infoNew.content,
          post_date: this.infoNew.postTime,
          category_news_id: this.infoNew.category,
          display_type: this.infoNew.typeDisplay,
          windows_hover_type: this.infoNew.typeCountDown,
          point: this.infoNew.point,
          number_point: this.infoNew.timeGrantPoint,
          is_public: this.infoNew.isPublic,
          content_id: listContentId,
          slag_id: listSlagId,
          countdown_name:
            this.infoNew.typeCountDown === 0 || this.infoNew.typeCountDown === 1
              ? this.infoNew.nameCountdown
              : "",
          countdown_date:
            this.infoNew.typeCountDown === 0 ? this.infoNew.dateCountdown : "",
          countdown_time_read:
            this.infoNew.typeCountDown === 1
              ? this.roundNumber(this.infoNew.timeReadCountdown, 1)
              : "",
          countdown_url:
            this.infoNew.typeCountDown !== 3 ? this.infoNew.urlCountdown : "",
          slag_id_1: listIdSlagVisible1,
          slag_id_2: listIdSlagVisible2,
          slag_id_3: listIdSlagVisible3,
          slag_id_4: listIdSlagHidden,
          url_redirect: urlRedirect,
        };
        if (request.id) {
          this.updateNew(request);
        } else {
          this.createNew(request);
        }
      }
    },
    roundNumber(num, dec) {
      return "" + Number(num).toFixed(dec);
    },
    addSlag() {
      if (this.listSlag.length > 0 && Object.keys(this.detailNew).length > 0) {
        if (this.detailNew.slag_id_1) {
          this.infoNew.slagIdVisible1 = this.listSlag
            .filter((item) => {
              return (
                JSON.parse(this.detailNew.slag_id_1).indexOf(item.id) !== -1
              );
            })
            .map((item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            }));
        } else {
          this.infoNew.slagIdVisible1 = [];
        }
        if (this.detailNew.slag_id_2) {
          this.infoNew.slagIdVisible2 = this.listSlag
            .filter((item) => {
              return (
                JSON.parse(this.detailNew.slag_id_2).indexOf(item.id) !== -1
              );
            })
            .map((item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            }));
        } else {
          this.infoNew.slagIdVisible2 = [];
        }
        if (this.detailNew.slag_id_3) {
          this.infoNew.slagIdVisible3 = this.listSlag
            .filter((item) => {
              return (
                JSON.parse(this.detailNew.slag_id_3).indexOf(item.id) !== -1
              );
            })
            .map((item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            }));
        } else {
          this.infoNew.slagIdVisible3 = [];
        }
        if (this.detailNew.slag_id_4) {
          this.infoNew.slagIdHidden = this.listSlag
            .filter((item) => {
              return (
                JSON.parse(this.detailNew.slag_id_4).indexOf(item.id) !== -1
              );
            })
            .map((item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            }));
        } else {
          this.infoNew.slagIdHidden = [];
        }
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedExtension.indexOf(file.type) <= -1) {
        this.$toasted.error("图片类型必须是.jpeg,jpg,png中的一种");
        resetUploader();
      } else if (file.size / 1024 / 1024 > 100) {
        this.$toasted.error("画像1枚につき100MBまで");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.shop_id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    check(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      return (
        (charCode === 46 &&
          this.infoNew.timeReadCountdown.indexOf(".") != -1) ||
        (charCode >= 48 && charCode <= 57)
      );
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.news-create {
  background: #fff;
  form {
    div {
      .label-news {
        background: #d5d5d5;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        padding: 0 20px;
      }
    }
  }
}
.label-required {
  width: 50px;
  text-align: center;
  background-color: red;
  color: #fff;
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
.create-content-main {
  .row-input {
    input {
      margin: 0;
    }
  }
}
//son
.edit-news-btn {
  height: 36px !important;
  width: 100px !important;
}
.ql-editor {
  border: 1px solid #ddd;
}
</style>
